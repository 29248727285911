import { useEffect, useState } from "react";

import { signIn } from "next-auth/react";
import { useRouter } from "next/router";
import { Alert } from "react-bootstrap";
import { Google } from "react-bootstrap-icons";

import { getDeviceId } from "helpers/storage";
import useUser from "hooks/useUser";

import SocialButtonOnboarding from "./SocialButtonOnboarding";

const SocialSignInOnboarding = ({ isLogin = false, disabled = false }: { isLogin?: boolean; disabled?: boolean }) => {
	const [errorType, setErrorType] = useState("");
	const [ssoLoggingIn, setSsoLoggingIn] = useState(false);
	const { query } = useRouter();
	const { isLoggedIn } = useUser();

	useEffect(() => {
		if (query.error) {
			setErrorType(query.error as string);
		}

		getDeviceId();
	}, [query.error]);

	const handleSSOLogin = (provider: string) => {
		setSsoLoggingIn(true);
		signIn(provider, { callbackUrl: "/my-garage" });
	};

	return (
		<div className="mt-3 w-full">
			<div className="mt-3">
				<SocialButtonOnboarding
					onClick={() => handleSSOLogin("google")}
					disabled={disabled || ssoLoggingIn}
					icon={<Google />}
					provider="Google"
					isLogin={isLogin}
					isLoading={ssoLoggingIn}
				/>
			</div>
			{/* <div className="mt-3">
				<SocialButtonOnboarding onClick={() => signIn("facebook")} disabled={disabled} icon={<Facebook />} provider="Facebook" isLogin={isLogin} />
			</div> */}
			{errorType && !isLoggedIn && (
				<Alert variant="danger" className="mt-3">
					{errorType === "AccessDenied" ? "Account is not found. Please sign up." : "Sorry, we could not log you in at this time. Please try again."}
				</Alert>
			)}
		</div>
	);
};

export default SocialSignInOnboarding;
