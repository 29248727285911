import { SelectFieldProps } from "model/object/fields";
import { Form } from "react-bootstrap";
import styles from "./field.module.css";

interface OptionItem {
	value: string;
	label: string;
}

export const SelectFieldNew = ({ label, value, name, handleChange, options, error, className, required = false, disabled = false }: SelectFieldProps) => {
	return (
		<>
			{label && <Form.Label>{label}</Form.Label>}
			<Form.Select
				name={name}
				value={value}
				onChange={handleChange}
				className={`${styles.selectField} ${className}`}
				isInvalid={!!error}
				required={required}
				disabled={disabled}
				defaultValue=""
			>
				<option value="" selected disabled hidden>
					Select option
				</option>
				{options.map(o => (
					<option key={o.value} value={o.value}>
						{o.label}
					</option>
				))}
			</Form.Select>
			<Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
		</>
	);
};

const SelectField = ({
	label,
	value,
	name,
	setFieldValue,
	options,
	error,
	className,
	onBlur,
	withDefault = true,
}: {
	label?: string;
	value: string;
	name: string;
	setFieldValue: (field: string, value: any) => void;
	options: OptionItem[];
	error?: string;
	className?: string;
	onBlur?: React.FocusEventHandler<HTMLSelectElement>;
	withDefault?: boolean;
}) => {
	return (
		<>
			{label && <Form.Label>{label}</Form.Label>}
			<Form.Select
				name={name}
				value={value}
				onChange={e => setFieldValue(name, e.target.value)}
				className={`${styles.selectField} ${className}`}
				isInvalid={!!error}
				onBlur={onBlur}
			>
				{withDefault && (
					<option disabled value="">
						Select
					</option>
				)}
				{options.map(o => (
					<option key={o.value} value={o.value}>
						{o.label}
					</option>
				))}
			</Form.Select>
		</>
	);
};

export default SelectField;
