import { useEffect } from "react";

import { Formik } from "formik";
import { GetServerSidePropsContext } from "next";
import Link from "next/link";
import { useRouter } from "next/router";
import { Alert, Form } from "react-bootstrap";

import PrimaryCta from "components/Buttons/PrimaryCta";
import { PasswordField } from "components/Fields";
import TextField from "components/Fields/TextField";
import SocialSignInOnboarding from "components/Social/SocialSignInOnboarding";
import useUser from "hooks/useUser";
import { getUserFromServer } from "lib/auth/auth";
import { loginSchema } from "model/schema/login";
import { useLoginUserMutation } from "store/services/main";

const Login = () => {
	const router = useRouter();
	const ref = router.query.ref;
	const { isLoggedIn } = useUser();
	const [loginUser, { isLoading, isSuccess, isError }] = useLoginUserMutation();

	useEffect(() => {
		if (isSuccess && !isError && !isLoading) {
			router.push("/my-garage");
		}
	}, [isSuccess, isError, isLoading, router]);

	useEffect(() => {
		if (isLoggedIn) {
			router.replace("/my-garage");
		}
	}, [isLoggedIn, router]);

	return (
		<>
			<div className=" bg-indigo pb-24 pt-3 text-center text-white">
				<h1 className="text-4xl">Welcome back</h1>
				<p>Log in with your account below</p>
			</div>
			<div className="left-0 right-0 -mt-16 mb-20 ml-auto mr-auto w-[40rem] rounded-2xl bg-white px-16 py-10 mobileL:!w-[90vw] tablet:!px-2 laptop:w-[80vw]">
				<div className="mt-3 text-black">
					<SocialSignInOnboarding isLogin />
				</div>
				<div className="flex items-center py-4 text-black">
					<div className="h-px flex-grow bg-gray"></div>
					<span className="flex-shrink px-4 !text-lg">Or log in with email</span>
					<div className="h-px flex-grow bg-gray"></div>
				</div>
				{ref && (
					<>
						{ref === "forgotPasswordSuccess" && (
							<Alert variant="success" className="!mb-5 text-center">
								Password has been successfully reset!
							</Alert>
						)}
					</>
				)}
				<div className="mt-3 text-black">
					<Formik
						validationSchema={loginSchema}
						onSubmit={async ({ email, password }): Promise<void> => {
							await loginUser({ email, password, userAgent: navigator.userAgent, source: "web" });
						}}
						initialValues={{
							email: "",
							password: "",
						}}
						validateOnMount
					>
						{({ handleSubmit, values, errors, handleChange, isValid, touched, setFieldTouched }) => (
							<Form onSubmit={handleSubmit}>
								<Form.Group className="mt-3 font-bold">
									<TextField
										label="Email address"
										name="email"
										type="text"
										placeholder="Email"
										value={values.email}
										handleChange={handleChange}
										onBlur={() => setFieldTouched("email")}
										error={touched.email ? errors.email : ""}
										required
									/>
								</Form.Group>
								<Form.Group className="mt-3 font-bold">
									<PasswordField
										label="Password"
										name="password"
										placeholder="Password"
										value={values.password}
										handleChange={handleChange}
										onBlur={() => setFieldTouched("password")}
										error={touched.password ? errors.password : ""}
										required
									/>
								</Form.Group>
								<div className="mt-3 text-right font-bold">
									<Link href="/password/forgot">Forgot password?</Link>
								</div>
								<Form.Group className="mt-3">
									{isError && (
										<Alert variant="danger">
											Sorry, we couldn&apos;t log you in at this time. Please check your password, reset your password or create an account with us.
										</Alert>
									)}
								</Form.Group>

								<Form.Group className="d-flex justify-content-center mt-3 ">
									<PrimaryCta
										text={!isSuccess && !isLoggedIn ? "Log in" : "Success"}
										onClick={handleSubmit}
										isSubmitting={isLoading}
										disabled={!isValid}
										className="!px-11"
										sent={isSuccess || isLoggedIn}
									/>
								</Form.Group>
							</Form>
						)}
					</Formik>
					<div className="justify-content-center left-0 right-0 ml-auto mr-auto mt-3 flex gap-2">
						<p>Don&apos;t have an account yet?</p>
						<Link href="/">Sign-up</Link>
					</div>
				</div>
			</div>
		</>
	);
};

export const getServerSideProps = async (context: GetServerSidePropsContext) => getUserFromServer(context);

export default Login;
