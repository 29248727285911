import { ChangeEvent } from "react";
import { Form } from "react-bootstrap";
import TextControl from "./TextControl";
import { TextFieldProps } from "model/object/fields";

const TextField = ({
	label,
	name,
	type,
	value,
	handleChange,
	required,
	error,
	disabled,
	autocomplete,
	className,
	placeholder,
	maxLength,
	max,
	min,
	minLength,
	onBlur,
	onFocus,
	hasAsterisk = false,
	hasErrorMessage = true,
}: TextFieldProps) => (
	<>
		{label && (
			<Form.Label>
				{label} {hasAsterisk && <span className="text-red">*</span>}
			</Form.Label>
		)}
		<TextControl
			type={type}
			name={name}
			value={value}
			handleChange={handleChange}
			required={required}
			error={error}
			disabled={disabled}
			data-test-id={name}
			autocomplete={autocomplete}
			className={className}
			placeholder={placeholder}
			maxLength={maxLength}
			max={max}
			min={min}
			minLength={minLength}
			onBlur={onBlur}
			onFocus={onFocus}
		/>
		{error && hasErrorMessage && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
	</>
);

export default TextField;
