import { ChangeEvent, useState } from "react";
import { Eye, EyeSlash } from "react-bootstrap-icons";

import { Form } from "react-bootstrap";
import TextControl from "./TextControl";

const PasswordField = ({
	label,
	name,
	value,
	handleChange,
	required,
	error,
	disabled,
	onBlur,
	placeholder,
}: {
	label?: string;
	name: string;
	placeholder: string;
	value?: string | string[] | number;
	handleChange?: (e: ChangeEvent) => void;
	required?: boolean;
	error?: string | undefined;
	disabled?: boolean;
	onBlur?: React.FocusEventHandler<HTMLInputElement>;
}) => {
	const [showPassword, setShowPassword] = useState(false);

	return (
		<>
			{label && <Form.Label>{label}</Form.Label>}
			<div className="input-group">
				<TextControl
					type={showPassword ? "text" : "password"}
					name={name}
					value={value}
					handleChange={handleChange}
					required={required}
					error={error}
					disabled={disabled}
					data-test-id={name}
					placeholder={placeholder}
					onBlur={onBlur}
				/>
				<button
					type="button"
					className="btn btn-outline-secondary d-flex align-items-center justify-content-center"
					style={{
						borderRadius: "0px 10px 10px 0px",
						borderColor: "rgb(206, 212, 218)",
					}}
					onClick={() => setShowPassword(!showPassword)}
				>
					{showPassword ? <EyeSlash color="#29b9dd" /> : <Eye color="#29b9dd" />}
				</button>
				<Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
			</div>
		</>
	);
};

export default PasswordField;
