import Image from "next/image";
import React from "react";
import buttonStyles from "../../styles/buttons.module.css";

type SocialButtonOnboardingProps = {
	onClick?: () => void;
	disabled: boolean;
	icon: React.ReactNode;
	provider: "Google" | "Facebook" | "Apple";
	isLogin: boolean;
	isLoading?: boolean;
};

const SocialButtonOnboarding = ({ onClick, disabled, icon, provider, isLogin, isLoading = false }: SocialButtonOnboardingProps) => {
	const startText = isLogin ? "Sign in with" : "Continue with";

	return (
		<button
			className={`btn ${buttonStyles["btn-social-onboarding"]} align-items-center flex  flex-row gap-4 px-3 py-2.5 ${provider === "Google" && "!border-gray"} ${
				provider === "Facebook" && "!bg-[#4267B2] !text-white"
			} ${provider === "Apple" && "!bg-black !text-white"}`}
			onClick={onClick}
			disabled={disabled}
		>
			<div>{icon}</div>
			<div className="">
				{startText} {provider}
			</div>
			{isLoading && (
				<div className="relative ml-auto h-[25px] w-[25px]">
					<Image src={require("public/compress-loading.gif")} alt="loading" layout="fill" objectFit="contain" />
				</div>
			)}
		</button>
	);
};

export default SocialButtonOnboarding;
