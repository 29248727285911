import { ChangeEvent } from "react";
import { Form } from "react-bootstrap";
import styles from "./field.module.css";
import { TextControlProps } from "model/object/fields";

const TextControl = ({
	name,
	type,
	value,
	handleChange,
	required,
	error,
	disabled,
	placeholder,
	className,
	maxLength,
	autocomplete,
	max,
	min,
	minLength,
	onBlur,
	onFocus,
}: TextControlProps) => {
	return (
		<Form.Control
			className={`${styles.field} ${className}`}
			type={type === "number" ? "text" : type}
			name={name}
			value={value}
			autoComplete={autocomplete}
			onChange={handleChange}
			required={required}
			isInvalid={!!error}
			disabled={disabled}
			data-test-id={name}
			placeholder={placeholder}
			maxLength={maxLength}
			max={max}
			min={min}
			minLength={minLength}
			onKeyPress={event => {
				if (type === "number" && !/[0-9]/.test(event.key)) {
					event.preventDefault();
				}
			}}
			onBlur={onBlur}
			onFocus={onFocus}
		/>
	);
};

export default TextControl;
